import React from "react";
import { SEO, MarkdownContent, Image } from "@bluefin/components";
import { Grid, Segment } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";

export default class EastCobbTeamPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          title={fishermanBusinessWebsitePage.seoTitle}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
          ogUrl={"https://www.bobsteele.com/east-cobb-team/"}
        />
        <Grid
          className={"custom-page-east-cobb-stylist custom-page-grid"}
          style={{}}
          stackable={true}
        >
          <Grid.Row
            className={"rypijcsxteimbbnx"}
            style={{ padding: 16 }}
            textAlign={"center"}
            verticalAlign={"middle"}
          >
            <Grid.Column
              className={"datbvcfawchkaiul"}
              style={{ padding: 16 }}
              width={12}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "69c6b3fb-fa96-4703-bca8-5a4e202fae11",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"wjtyzcnhradtwwdf"}
            style={{ padding: 16 }}
            textAlign={"center"}
          >
            <Grid.Column
              className={"btyuzsqlfltlmfar"}
              style={{ padding: 16 }}
              width={4}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{
                    boxShadow: " 0 0 0 2px #C7A975",
                    maxHeight: "600px",
                    minHeight: "240px",
                    backgroundSize: "contain",
                  }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "aaf4684b-8c61-4253-b193-60b4ffb7b959",
                    numToSelect: 1,
                  })}
                  background={true}
                >
                  <MarkdownContent
                    className={"text-block"}
                    style={{
                      top: 0,
                      left: 0,
                      color: "#FFFFFF",
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      padding: "16px",
                      position: "absolute",
                      overflowY: "scroll",
                      alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                    }}
                    content={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "MarkdownContent",
                      componentId: "2fd59b16-c1bd-46ac-8cad-5d39ec21861f",
                      defaultValue: "",
                    })}
                  />
                </Image>
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"brlcqgblsglotkyc"}
              style={{ padding: 16 }}
              width={4}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{
                    boxShadow: " 0 0 0 2px #C7A975",
                    maxHeight: "600px",
                    minHeight: "240px",
                    backgroundSize: "contain",
                  }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "060b2cc9-fe6a-4a79-bb34-f3390e2822f7",
                    numToSelect: 1,
                  })}
                  background={true}
                >
                  <MarkdownContent
                    className={"text-block"}
                    style={{
                      top: 0,
                      left: 0,
                      color: "#FFFFFF",
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      padding: "16px",
                      position: "absolute",
                      overflowY: "scroll",
                      alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                    }}
                    content={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "MarkdownContent",
                      componentId: "9f013360-b164-4258-bd6d-85a16db8d908",
                      defaultValue: "",
                    })}
                  />
                </Image>
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"jaxdynzqlvqbfldi"}
              style={{ padding: 16 }}
              width={4}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{
                    boxShadow: " 0 0 0 2px #C7A975",
                    maxHeight: "600px",
                    minHeight: "240px",
                    backgroundSize: "contain",
                  }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "b993b86b-3ebd-4642-8676-90547632eaf9",
                    numToSelect: 1,
                  })}
                  background={true}
                >
                  <MarkdownContent
                    className={"text-block"}
                    style={{
                      top: 0,
                      left: 0,
                      color: "#FFFFFF",
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      padding: "16px",
                      position: "absolute",
                      overflowY: "scroll",
                      alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                    }}
                    content={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "MarkdownContent",
                      componentId: "c7541829-b63a-4b4c-9a4b-42a881253041",
                      defaultValue: "",
                    })}
                  />
                </Image>
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"qyhowbkrbmsqtbfh"}
            style={{ padding: 16 }}
            textAlign={"center"}
          >
            <Grid.Column
              className={"deizehpqgwhtmclu"}
              style={{ padding: 16 }}
              width={4}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{
                    boxShadow: " 0 0 0 2px #C7A975",
                    maxHeight: "600px",
                    minHeight: "240px",
                    backgroundSize: "contain",
                  }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "8d31f890-00be-4f6b-a9c6-59d741653f66",
                    numToSelect: 1,
                  })}
                  background={true}
                >
                  <MarkdownContent
                    className={"text-block"}
                    style={{
                      top: 0,
                      left: 0,
                      color: "#FFFFFF",
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      padding: "16px",
                      position: "absolute",
                      overflowY: "scroll",
                      alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                    }}
                    content={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "MarkdownContent",
                      componentId: "d2d4e51f-f791-485c-8bbe-04dfc876a58d",
                      defaultValue: "",
                    })}
                  />
                </Image>
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"xnulcapulzqjkbkv"}
              style={{ padding: 16 }}
              width={4}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{
                    boxShadow: " 0 0 0 2px #C7A975",
                    maxHeight: "600px",
                    minHeight: "240px",
                    backgroundSize: "contain",
                  }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "876dfe85-f1d3-4d53-b238-c078da516bfb",
                    numToSelect: 1,
                  })}
                  background={true}
                >
                  <MarkdownContent
                    className={"text-block"}
                    style={{
                      top: 0,
                      left: 0,
                      color: "#FFFFFF",
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      padding: "16px",
                      position: "absolute",
                      overflowY: "scroll",
                      alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                    }}
                    content={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "MarkdownContent",
                      componentId: "c7ba41b2-4663-4822-a1cd-a54189968cd0",
                      defaultValue: "",
                    })}
                  />
                </Image>
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"tqfgteznstpwtpln"}
              style={{ padding: 16 }}
              width={4}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{
                    boxShadow: " 0 0 0 2px #C7A975",
                    maxHeight: "600px",
                    minHeight: "240px",
                    backgroundSize: "contain",
                  }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "fe5509b8-e2e5-4480-873a-61a67156b9d4",
                    numToSelect: 1,
                  })}
                  background={true}
                >
                  <MarkdownContent
                    className={"text-block"}
                    style={{
                      top: 0,
                      left: 0,
                      color: "#FFFFFF",
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      padding: "16px",
                      position: "absolute",
                      overflowY: "scroll",
                      alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                    }}
                    content={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "MarkdownContent",
                      componentId: "21be755f-b22c-4106-964b-351ce62bdc00",
                      defaultValue: "",
                    })}
                  />
                </Image>
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"mwobfzngfpzlzyxl"}
            style={{ padding: 16 }}
            textAlign={"center"}
          >
            <Grid.Column
              className={"rscslnsjtbxaueyt"}
              style={{ padding: 16 }}
              width={4}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{
                    boxShadow: " 0 0 0 2px #C7A975",
                    maxHeight: "600px",
                    minHeight: "240px",
                    backgroundSize: "contain",
                  }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "51b7bc6c-17d9-4dba-81cb-bbadc90a8f0c",
                    numToSelect: 1,
                  })}
                  background={true}
                >
                  <MarkdownContent
                    className={"text-block"}
                    style={{
                      top: 0,
                      left: 0,
                      color: "#FFFFFF",
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      padding: "16px",
                      position: "absolute",
                      overflowY: "scroll",
                      alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                    }}
                    content={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "MarkdownContent",
                      componentId: "df2a8d43-b18f-40fb-9d61-17859fd99e57",
                      defaultValue: "",
                    })}
                  />
                </Image>
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"vshpmvgxmhqmwabp"}
              style={{ padding: 16 }}
              width={4}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{
                    boxShadow: " 0 0 0 2px #C7A975",
                    maxHeight: "600px",
                    minHeight: "240px",
                    backgroundSize: "contain",
                  }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "e1c8969a-8e7e-4d7c-9b5e-ca34e1684835",
                    numToSelect: 1,
                  })}
                  background={true}
                >
                  <MarkdownContent
                    className={"text-block"}
                    style={{
                      top: 0,
                      left: 0,
                      color: "#FFFFFF",
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      padding: "16px",
                      position: "absolute",
                      overflowY: "scroll",
                      alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                    }}
                    content={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "MarkdownContent",
                      componentId: "d0dce299-ae7b-4b88-9d55-65f71a8f863d",
                      defaultValue: "",
                    })}
                  />
                </Image>
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"jjpdgszusbiezvul"}
              style={{ padding: 16 }}
              width={4}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{
                    boxShadow: " 0 0 0 2px #C7A975",
                    maxHeight: "600px",
                    minHeight: "240px",
                    backgroundSize: "contain",
                  }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "d019a7d1-2e16-4f20-9c74-5ca538a5dc52",
                    numToSelect: 1,
                  })}
                  background={true}
                >
                  <MarkdownContent
                    className={"text-block"}
                    style={{
                      top: 0,
                      left: 0,
                      color: "#FFFFFF",
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      padding: "16px",
                      position: "absolute",
                      overflowY: "scroll",
                      alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                    }}
                    content={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "MarkdownContent",
                      componentId: "4629c747-5f40-4138-af7e-fa1e5cd0c0f4",
                      defaultValue: "",
                    })}
                  />
                </Image>
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"mzerbydklsdpaqzv"}
            style={{ padding: 16 }}
            textAlign={"center"}
          >
            <Grid.Column
              className={"ftlcspqcvttyiuyx"}
              style={{ padding: 16 }}
              width={4}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{
                    boxShadow: " 0 0 0 2px #C7A975",
                    maxHeight: "600px",
                    minHeight: "240px",
                    backgroundSize: "contain",
                  }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "09f21eb4-361a-4b7b-a0a4-590990a73d8f",
                    numToSelect: 1,
                  })}
                  background={true}
                >
                  <MarkdownContent
                    className={"text-block"}
                    style={{
                      top: 0,
                      left: 0,
                      color: "#FFFFFF",
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      padding: "16px",
                      position: "absolute",
                      overflowY: "scroll",
                      alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                    }}
                    content={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "MarkdownContent",
                      componentId: "cf82b5e8-1990-4408-8f26-b1d2341f6294",
                      defaultValue: "",
                    })}
                  />
                </Image>
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"cdbhlhakpfhqexfl"}
              style={{ padding: 16 }}
              width={4}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{
                    boxShadow: " 0 0 0 2px #C7A975",
                    maxHeight: "600px",
                    minHeight: "240px",
                    backgroundSize: "contain",
                  }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "6747f49a-0480-44a6-a272-029d77b9aa2a",
                    numToSelect: 1,
                  })}
                  background={true}
                >
                  <MarkdownContent
                    className={"text-block"}
                    style={{
                      top: 0,
                      left: 0,
                      color: "#FFFFFF",
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      padding: "16px",
                      position: "absolute",
                      overflowY: "scroll",
                      alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                    }}
                    content={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "MarkdownContent",
                      componentId: "eb828a8e-6400-45f1-afae-f1c33377df66",
                      defaultValue: "",
                    })}
                  />
                </Image>
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"hvppjrskonatgidg"}
              style={{ padding: 16 }}
              width={4}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{
                    boxShadow: " 0 0 0 2px #C7A975",
                    maxHeight: "600px",
                    minHeight: "240px",
                    backgroundSize: "contain",
                  }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "cc996413-2b2c-4054-bc80-136fa92197c3",
                    numToSelect: 1,
                  })}
                  background={true}
                >
                  <MarkdownContent
                    className={"text-block"}
                    style={{
                      top: 0,
                      left: 0,
                      color: "#FFFFFF",
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      padding: "16px",
                      position: "absolute",
                      overflowY: "scroll",
                      alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                    }}
                    content={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "MarkdownContent",
                      componentId: "77357245-2c71-4bea-8143-3585fb32dcdb",
                      defaultValue: "",
                    })}
                  />
                </Image>
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"leeuqtqvaczuykau"}
            style={{ padding: 16 }}
            textAlign={"center"}
          >
            <Grid.Column
              className={"cjhnlydrdomjbsco"}
              style={{ padding: 16 }}
              width={4}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{
                    boxShadow: " 0 0 0 2px #C7A975",
                    maxHeight: "600px",
                    minHeight: "240px",
                    backgroundSize: "contain",
                  }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "67f67ca0-b664-4394-bbfd-cf44b3ad66ad",
                    numToSelect: 1,
                  })}
                  background={true}
                >
                  <MarkdownContent
                    className={"text-block"}
                    style={{
                      top: 0,
                      left: 0,
                      color: "#FFFFFF",
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      padding: "16px",
                      position: "absolute",
                      overflowY: "scroll",
                      alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                    }}
                    content={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "MarkdownContent",
                      componentId: "c58148d1-1920-441d-a3d4-cf8d4b2d5b44",
                      defaultValue: "",
                    })}
                  />
                </Image>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(_id: { eq: 26949 }) {
      title
      seoTitle
      description
      components {
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        order
        fastId
        componentIdentifier
        contentNodes {
          content
        }
      }
    }
  }
`;
